import React, { Component } from 'react';

export default class PortfolioItem extends Component {
  constructor(props){
    super(props);
  }
  render() {
    let imageSrc = "./images/portfolio/" + this.props.data.displayPhoto;
    return (
      <div className="columns portfolio-item">
        <div className="item-wrap">
          <a href={'#modal-' + this.props.data.modalNumber} title="true">
            <img src={imageSrc} />
            <div className="overlay">
              <div className="portfolio-item-meta">
                <h5>{this.props.data.projectName}</h5>
                <p>{this.props.data.description}</p>
              </div>
            </div>
            <div className="link-icon"><i className="icon-plus" /></div>
          </a>
        </div>
      </div>
    )
  }
}

