import React, { Component } from 'react';
import Header from './components/header/header';
import About from './components/about/about';
import Resume from './components/resume/resume';
import Portfolio from './components/portfolio/portfolio';
import Footer from './components/footer/footer';
import resumeData from './resumeData';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-147999291-1');

class App extends Component {
  useEffect() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div className="App">
        <Header resumeData={resumeData}/>
        {/* <About resumeData={resumeData}/> */}
        <Resume resumeData={resumeData}/>
        <Portfolio resumeData={resumeData}/>
        <Footer resumeData={resumeData}/>
      </div>
    );
  }
}
export default App;
