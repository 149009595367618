import React, { Component } from 'react';
import Typewriter from 'typewriter-effect';
import ReactGA from 'react-ga';

export default class header extends Component {
  
  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    
    let resumeData = this.props.resumeData;
    return (
      <div className="header">
        <header id="home">
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
          <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
          <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
            {/* <li><a className="smoothscroll" href="#about">About</a></li> */}
            {/* <li><a className="smoothscroll" href="#resume">Resume</a></li> */}
            <li><a className="smoothscroll" href="#portfolio">Projects</a></li>
            {/* <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li> */}
            {/* <li><a className="smoothscroll" href="#contact">Contact</a></li> */}
          </ul> {/* end #nav */}
        </nav> {/* end #nav-wrap */}
        <div className="row banner">
          <div className="banner-text">
            <img className="profile-pic" src="https://avatars2.githubusercontent.com/u/29294465?s=460&v=4" alt="true" />
            <h1 className="responsive-headline line-1 anim-typewriter">I'm Yuchen He</h1>
            <h3>And I am a ...</h3>
            <h3><Typewriter options={{
              strings: ['Software Engineer', 'ML Student', 'Full Stack Web Dev', 'Snowboarder', 'Marathon Runner', 'Keyboard Enthusiast'],
              autoStart: true,
              loop: true
              }}
            /></h3>
            {/* <h3>I am a Chinese-Canadian software developer with experience in
              <span> Javascript</span>, <span> Java </span> and <span> Python</span> with a lot of determination
            and detail-oriented goals
            </h3> */}
            {/* <hr /> */}
            <ul className="social">
              <li><a target="_blank" href="https://www.facebook.com/yuchen0420"><i className="fa fa-facebook" id="facebook"/></a></li>
              {/* <li><a target="_blank" href="https://twitter.com/yc_he27"><i className="fa fa-twitter" id="twitter"/></a></li> */}
              <li><a target="_blank" href="https://www.linkedin.com/in/yu-chen-he/"><i className="fa fa-linkedin" id="linkedin"/></a></li>
              <li><a href="https://github.com/YuChenHeMTL"><i className="fa fa-github" id="github"/></a></li>
              <li><a href="mailto:yc4he@uwaterloo.ca"><i className="fa fa-envelope" id="mail"/></a></li>
              <li><a href="/yuchen_he_resume.pdf" target="_blank">Resume</a></li>
            </ul>
            <div className="ResumeDoc"></div>
            <div className="three columns download">
              <p>
                
              </p>
            </div>
          </div>
        </div>
        <p className="scrolldown">
          <a className="smoothscroll" href="#portfolio"><i className="icon-down-circle" /></a>
        </p>
        </header>
      </div>
    )
  }
}
