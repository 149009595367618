import React from "react";

function Skills() {
    let resumeSkills = require('../resume_skills.json');

    return (
        <div className="row skill">
          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
              <div className="bars">
                <h3>Languages</h3>
                <div className="row">
                      {resumeSkills.languages.map(data => {
                        return <div className="one column item">{data}</div>;
                      })}
                </div>
                <h3>Frameworks</h3>
                <div className="row">
                    {resumeSkills.frameworks.map(data => {
                      return <div className="one column item">{data}</div>;
                    })}
                </div>
              </div>
              </div>
            </div>
          </div> {/* main-col end */}
        </div>
    )
}

export default Skills;