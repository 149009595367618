import React, { Component } from 'react';

function School() {
    return (
        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                <h3>University of Waterloo</h3>
                <p className="info">Undergraduate in Software Engineering<span>•</span> <em className="date">June 2023</em></p>
                <p>
                - Bachelor's of Engineering (BSE) program, major in Software Engineering<br />
                - Recipient of the President's Scholarship of Distinction, valued at $2,000 <br />
                </p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>College Marianopolis</h3>
                <p className="info">Diploma in Pure and Applied Science<span>•</span> <em className="date">June 2018</em></p>
                <p>Post-secondary education pre-university</p>
              </div>
            </div>{/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>Lighthouse Web Development Summer Bootcamp</h3>
                <p className="info">Diploma in Web Development<span>•</span> <em className="date">September 2017</em></p>
                <p>
                Intensive full-stack web development program that teaches 
                industrial-grade coding skills including project building and version control 
                and employment-ready level of front end and back end programming languages (Javascript, 
                React, Node.js, PostgreSQL, and Ruby on Rails).
                </p>
              </div>
            </div> {/* item end */}
          </div> {/* main-col end */}
        </div>
    )
}

export default School;