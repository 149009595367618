import React, { Component } from 'react';

export default class about extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <div className="about">
        <section id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src="https://avatars2.githubusercontent.com/u/29294465?s=460&v=4" alt="true" />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>
              {/* Hi ! My name is Kevin (Yu Chen) He, and I am currently a 2nd year Software Engineering student at 
              the University of Waterloo. I am a passionate developer that has a lot of 
              experience in web development and am currently juggling studies and learning about other programming areas
              such as AI and software development. I have been a fan of solving puzzles and even though I have started coding
              not that long ago, so I am driven to learn all about it.  */}

              Hi! My name is Kevin He, and I am currently heading to my 3rd year as a Software Engineering student at the University of Waterloo. 
              I am a passionate developer that has a lot of experience in web developement, both in personal projects and full stack internships, and 
              I'm currently juggling between studies and learning more about machine learning, which I'm also very interested in. <br/>
              <br />
              On top of that, I am an avid fan of badminton and snowboarding, and I am currently training for the Shanghai Marathon this winter! <br/>
              <br />
              So, if you have an internship opportunity for Summer 2021, or if you just want to chat about common hobbies, feel free to contact me on my social
              media platforms!
            </p>
            <div className="row">
              <div className="nine columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>Phone number: <br/>+1 (514) 623-7568 (Canada)</span><br/>
                  <span>+86 131-2125-4470 (China)</span><br />
                  <span>Email: yc4he@uwaterloo.ca</span><br/>
                  <span>Wechat: 13121254470</span><br/>
                  <span>QQ: 2488029720</span>
                </p>
              </div>
              <div className="three columns download">
                <p>
                  <a href="https://drive.google.com/file/d/1DI1rY6E2LSRToUbKctCsMj7eaFOyYhsQ/view?usp=sharing" className="button"><i className="fa fa-download" />Download Resume</a>
                </p>
              </div>
            </div> 
          </div> 
        </div>
      </section> 
      </div>
    )
  }
}
