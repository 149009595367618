import React, { Component } from 'react';

export default class footer extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <div className="footer">
      <footer>
        <div className="row">
          <div className="twelve columns">
            {/* <p className="lead"></p> */}
            <ul className="social-links">
            <li><a target="_blank" href="https://www.facebook.com/yuchen0420"><i className="fa fa-facebook" id="facebook"/></a></li>
              {/* <li><a target="_blank" href="https://twitter.com/yc_he27"><i className="fa fa-twitter" id="twitter"/></a></li> */}
              <li><a target="_blank" href="https://www.linkedin.com/in/yu-chen-he/"><i className="fa fa-linkedin" id="linkedin"/></a></li>
              <li><a href="https://github.com/YuChenHeMTL"><i className="fa fa-github" id="github"/></a></li>
              <li><a href="mailto:yc4he@uwaterloo.ca"><i className="fa fa-envelope" id="mail"/></a></li>
              <li><a href="/Kevin_He_resume_UWaterloo.pdf" target="_blank">Resume</a></li>
            </ul>
          </div>
          <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open" /></a></div>
        </div>
        {/* <div id="mention">Built using React.js</div> */}
      </footer> {/* Footer End*/}
      </div>
    )
  }
}
