import React, { Component } from 'react';
import PortfolioItem from './portfolioItem';
import PortfolioModal from './portfolioModal';

export default class portfolio extends Component {
  render() {
    let portfolio_data = require("./portfolio_data.json");

    return (
      <div className="portfolio">
        <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Check Out Some of My Projects !</h1>
            {/* portfolio-wrapper */}
            <div id="portfolio-wrapper" className="bgrid-thirds s-bgrid-halves cf">
              {portfolio_data.portfolio_items.map(data => {
                return <PortfolioItem data={data} />;
              })}
            </div> {/* portfolio-wrapper end */}
          </div> {/* twelve columns end */}
          {/* Modal Popup
	      --------------------------------------------------------------- */}
          {portfolio_data.portfolio_items.map(data => {
            return <PortfolioModal data={data}/> ;
          })}
        </div> {/* row End */}
      </section> {/* Portfolio Section End*/}
      </div>
    )
  }
}
