import React from 'react';
import School from './subcomponents/school';
import WorkExperience from './subcomponents/work_experience';
import Skills from './subcomponents/skills';

function Resume() {
    return (
      <div className="resume">
        <section id="resume">
        <School />
        {/* Work
      ----------------------------------------------- */}
        <WorkExperience />
        {/* Skills
      ----------------------------------------------- */}
        <Skills />
      </section> {/* Resume Section End*/}
      </div>
    )
}
export default Resume;
