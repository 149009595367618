import React, { Component } from 'react';

export default class PortfolioModal extends Component {
    constructor(props){
        super(props);
    }
    render(){
        let herokuLink = (this.props.data.herokuLink) ? (<a href={this.props.data.herokuLink}>Demo</a>) : null;
        let githubLink = (this.props.data.githubLink) ? (<a href={this.props.data.githubLink} className="popup-modal-link">Github</a>) : null;
        let gitlabLink = (this.props.data.gitlabLink) ? (<a href={this.props.data.gitlabLink}>GitLab</a>) : null;

        let tags = this.props.data.tags.join(", ")
        let imageSrc = "images/portfolio/modals/" + this.props.data.modalPhoto;
        return(
            <div id={"modal-" + this.props.data.modalNumber} className="popup-modal mfp-hide">
                <img className="scale-with-grid" src={imageSrc} />
                <div className="description-box">
                <h4>{this.props.data.projectName}</h4>
                <p>{this.props.data.description}</p>
                <span className="categories"><i className="fa fa-tag" />{tags}</span>
                </div>
                <div className="link-box">
                {herokuLink} {gitlabLink} {githubLink}
                <a className="popup-modal-dismiss">Close</a>
                </div>
            </div>
        )
    }
}