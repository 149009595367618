import React from 'react';

function WorkExperience() {
    return (
        <div className="row work">
          <div className="three columns header-col">
            <h1><span>Work Experience</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                <h3>Amplitude</h3>
                <p className="info">Software Engineering Intern<span>•</span> <em className="date">Jan. 2022 - Aug. 2022</em></p>
                <p>
                - Worked on the Integrations team’s user property export (cohort export) and user action forwarding (event
                  streaming) from Amplitude to partner platforms allowing them to perform ad targeting and marketing analytics<br />
                - Implemented over 10 partner collaborations ‑ Integrations ‑ for cohort export and event streaming, including
                  Google Ads and Meta Pixel, and bringing in over 20 customers and serving 200k+ events per week <br />
                - Won the award for the best project for addressing customer needs out of 45 teams for designing and developing
                  a SDK migration extension in VSCode using JavaScript and ESLint during internal hackathon <br />
                - Frameworks / Tools: Python, Java, React, TypeScript, GraphQL, Docker
                </p>
              </div>
            </div> {/* item end */}
          <div className="row item">
              <div className="twelve columns">
                <h3>Blackberry</h3>
                <p className="info">Software Engineering Intern<span>•</span> <em className="date">May 2021 - Aug. 2021</em></p>
                <p>
                - Took part in the development of a log anomaly detection system used for program record stored in ElasticSearch<br />
                - Increased model prediction accuracy by 95% by implementing a combination of Naive Bayesian and Random Forest models alongside the feedback pipeline <br />
                - Introduced internal analyst feedback to the system by creating a Python pipeline that retrains the recognition
                  ML model using revised ground truth data extracted from internal database<br />
                - Frameworks / Tools: Python, Django, Tensorflow, Jenkins, Linux
                </p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>Tencent</h3>
                <p className="info">Machine Learning Engineering Intern<span>•</span> <em className="date">Oct. 2020 - Jan. 2021</em></p>
                <p>
                - Improved the efficiency of model training by 11% by implementing a cloud‑based module that optimizes the hyperparameter set in order to reduce training time using Pytorch and Flask <br />
                - Decreased training time of existing deep learning model training projects using SOTA earlystop techniques and AutoML in Tensorflow <br />
                - Deployed said module using Bash and Docker to production environment of 5000+ ML engineers <br />
                </p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>Bilibili</h3>
                <p className="info">E‑Commerce Engineering Intern<span>•</span> <em className="date">Jan. 2020 - May 2020</em></p>
                <p>
                - Worked on the Phoenix project, a scraping system for product data from external websites, which compares sales
                  data of figurine products with competitors’ revenue volume to evaluate current business strategies<br/>
                - Increased the precision of the product name matching algorithm by 12% by implementing word separation analysis and adding one-hot encoding in conjunction with cosine similarity algorithm<br/>
                - Raised the efficiency of the system by 700% by redesigning the application using Puppeteer in Node.js and load
                  balancer, capable of handling 8000+ requests per hour<br/>
                {/* - Enhanced the project's anti-scraping bypass methods, including implementing ADSL IP proxy rotation and building website-specific cookies + user-agent database<br/> */}
                - Frameworks / Tools: Javascript, Selenium, Node.js, Java Spring, MySQL
                </p>
              </div>
            </div> {/* item end */}
            <div className="row item">
              <div className="twelve columns">
                <h3>Ford Motor Company</h3>
                <p className="info">Full-Stack Developer<span>•</span> <em className="date">May 2019 - Aug. 2019</em></p>
                <p>
                   - Worked for the Cloud Data team which summarized critical vehicle crash reports for hardware engineers <br/>
                   - Executed a major overhaul to the bug report system’s UI, adding better visuals and vital vehicle info, by revamping
                      project frontend in JavaScript and adding REST APIs <br/>
                   {/* - Increased frontend report clarity and critical data awareness by executing a major overhaul to the processing system's Knockout.js UI, adding better visuals and gathering vital build information <br/> */}
                   - Frameworks / Tools: Javascript, Node.js, React, Java Spring, Python, REST APIs <br />
                </p>
              </div>
            </div> {/* item end */}
            {/* <div className="row item">
              <div className="twelve columns">
                <h3>Superkids E-learning</h3>
                <p className="info">Mathematics Tutor<span>•</span> <em className="date">January 2015 - June 2018</em></p>
                <p>
                - Assisted the students in extracurricular math knowledge learning and in various demanding school assignments. <br />
                - Elucidated in easy-to-understand terms various complex mathematical notions<br />
                - Conveyed the student's progress  in school and in extracurricular exercises with parents<br />
                - Coordinated teacher assistants to deliver efficiency in tutoring<br />
                </p>
              </div>
            </div> item end */}
          </div> {/* main-col end */}
        </div>
    )
}

export default WorkExperience;